import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_list = _resolveComponent("list");

  var _component_Tabs = _resolveComponent("Tabs");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "党校培训"
  }), _createVNode(_component_Tabs, {
    title: _ctx.titleArr,
    onChangeOther: _ctx.changeOther
  }, {
    content0: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table1",
        "tabs-index": 0,
        "api-name": "getMeetingListTrain"
      }, null, 512), [[_vShow, _ctx.tabsIndex == 0]])];
    }),
    content1: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table2",
        "tabs-index": 1,
        "api-name": "getReadyStartTrain"
      }, null, 512), [[_vShow, _ctx.tabsIndex == 1]])];
    }),
    content2: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table3",
        "tabs-index": 2,
        "api-name": "getReadyStartTrain"
      }, null, 512), [[_vShow, _ctx.tabsIndex == 2]])];
    }),
    _: 1
  }, 8, ["title", "onChangeOther"])], 64);
}